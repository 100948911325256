@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: #9696A8;
  border-bottom: 1.6px solid;
  font-weight: 600;
}

button {
  letter-spacing: 0.5px;
  border: 2px solid #000;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  padding: 6px 20px;
}

button:hover {
  background-color: #E4EEEF;
}
